import {
    type Coupon,
    type Package,
    type PreviewImage,
    type Property,
} from "@interfaces";
import React, {
    type Dispatch,
    type ReactNode,
    type SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    getCouponListAPI,
    getPackageListAPI,
    getPropertyAPI,
    getPublicPropertyAPI,
} from "./properties";
import { defaultProperty } from "defaults/property.default";
import { useLocalStorageState } from "hooks";
import { type ChangeTextAreaEvent, type ChangeInputEvent } from "@types";
import { useApp } from "./app-context";

export type CurrentStep = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

interface Step {
    idx: CurrentStep;
    label: string;
}

const STEPS: Step[] = [
    { idx: 0, label: "Allgemeines" },
    { idx: 1, label: "Grundinformationen" },
    { idx: 2, label: "Addresse des Objekts" },
    { idx: 3, label: "Energieausweis" },
    { idx: 4, label: "Preisgestaltung" },
    { idx: 5, label: "Ausstattung &  Merkmale" },
    { idx: 6, label: "Überschrift der Anzeige" },
    { idx: 7, label: "Kontaktinformationen" },
    { idx: 8, label: "Bilder" },
    { idx: 9, label: "Überblick" },
];

interface PropertyEditContextType {
    STEPS: Step[];
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<CurrentStep>>;
    property: Property;
    coupons: Coupon[];
    previewImages: PreviewImage[];
    validationErrors: String[];
    loadingImages: boolean;
    setValidationErrors: Dispatch<SetStateAction<String[]>>;
    setLoadingImages: Dispatch<SetStateAction<boolean>>;
    setProperty: Dispatch<SetStateAction<Property>>;
    setPreviewImages: Dispatch<SetStateAction<PreviewImage[]>>;
    handleTextChange: (e: ChangeInputEvent, regex?: RegExp) => void;
    handleTextAreaChange: (e: ChangeTextAreaEvent) => void;
    handleNumberChange: (
        e: ChangeInputEvent,
        regex?: RegExp,
        maxLength?: number,
    ) => void;
    handleDateChange: (e: ChangeInputEvent) => void;
    handleCheckboxChange: (e: ChangeInputEvent) => void;
    handleRadioButtonChange: (e: ChangeInputEvent, fields: string[]) => void;
    handleSelectChange: (selectOption: string, name: string) => void;
}

interface PropertyEditContextProviderProps {
    children: ReactNode;
}

const defaultContext = {
    currentStep: 0,
    setCurrentStep: () => {},
    STEPS,
    property: defaultProperty,
    previewImages: [],
    coupons: [],
    validationErrors: [],
    loadingImages: false,
    setLoadingImages: () => {},
    setProperty: () => {},
    setValidationErrors: () => {},
    setPreviewImages: () => {},
    handleTextChange: (e: ChangeInputEvent) => {},
    handleRadioButtonChange: (e: ChangeInputEvent) => {},
    handleTextAreaChange: (e: ChangeTextAreaEvent) => {},
    handleNumberChange: (e: ChangeInputEvent) => {},
    handleDateChange: (e: ChangeInputEvent) => {},
    handleCheckboxChange: (e: ChangeInputEvent) => {},
    handleSelectChange: (selectOption: string, name: string) => {},
};

export const PropertyEditContext =
    createContext<PropertyEditContextType>(defaultContext);

export const usePropertyEdit = () => useContext(PropertyEditContext);

export const PropertyEditProvider = ({
    children,
}: PropertyEditContextProviderProps) => {
    const { id } = useParams();
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState<CurrentStep>(0);
    const [property, setProperty] = useState<Property>(defaultProperty);
    const [validationErrors, setValidationErrors] = useState<String[]>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);

    const [coupons, setCoupons] = useLocalStorageState<Coupon[]>("coupons", []);
    const [previewImages, setPreviewImages] = useState<PreviewImage[]>([]);

    const handleNumberChange = (
        e: ChangeInputEvent,
        regex?: RegExp,
        maxLength?: number,
    ) => {
        const { name, value } = e.target;
        if (maxLength && value.length > maxLength) return;
        const parsedValue = regex
            ? Number(value.replace(regex, ""))
            : Number(value);
        setProperty((prev) => ({ ...prev, [name]: parsedValue }));
    };

    const handleTextChange = (e: ChangeInputEvent, regex?: RegExp) => {
        const { name, value } = e.target;
        const parsedValue = regex ? value.replace(regex, "") : value;
        setProperty((prev) => ({ ...prev, [name]: parsedValue }));
    };
    const handleTextAreaChange = (e: ChangeTextAreaEvent) => {
        const { name, value } = e.target;
        setProperty((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (e: ChangeInputEvent) => {
        const { name, value } = e.target;
        setProperty((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e: ChangeInputEvent) => {
        const { name } = e.target;
        const isSelected = property[name as keyof Property];
        setProperty((prev) => ({ ...prev, [name]: Boolean(!isSelected) }));
    };

    const handleRadioButtonChange = (e: ChangeInputEvent, fields: string[]) => {
        const { value: selectedField } = e.target;
        const otherFields = fields.filter((field) => field !== selectedField);

        const newProperty: any = { ...property } as Property;
        for (const field of otherFields) {
            newProperty[field as keyof Property] = false as boolean;
        }

        setProperty((prev) => ({
            ...prev,
            ...newProperty,
            [selectedField]: Boolean(true),
        }));
    };

    const handleSelectChange = (selectOption: string, name: string) => {
        setProperty((prev) => ({ ...prev, [name]: selectOption }));
    };
    const { user } = useApp();

    useEffect(() => {
        const fetchPropertyToEdit = async () => {
            if (!id) return;
            const res = !location.pathname.includes("public")
                ? await getPropertyAPI(id)
                : await getPublicPropertyAPI(id);
            setProperty(res);
        };
        fetchPropertyToEdit();
    }, [id]);

    return (
        <PropertyEditContext.Provider
            value={{
                currentStep,
                setCurrentStep,
                STEPS,
                previewImages,
                loadingImages,
                setLoadingImages,
                coupons,
                property,
                validationErrors,
                setValidationErrors,
                setProperty,
                setPreviewImages,
                handleTextChange,
                handleNumberChange,
                handleSelectChange,
                handleDateChange,
                handleCheckboxChange,
                handleRadioButtonChange,
                handleTextAreaChange,
            }}
        >
            {children}
        </PropertyEditContext.Provider>
    );
};
