import { EstateSyncProperty, Property } from "@interfaces";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Tooltip,
} from "@mui/material";
import { margin } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import {
    deleteAndUnsyncPropertyAPI,
    getAllSyncedPropertiesAPI,
    toggleEstateSyncAPI,
    unsyncAllPropertiesAPI,
    useApp,
} from "services";
import { createPaymentLinkAPI } from "services/payments";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginPage from "./LoginPage";

const styles = {
    link: {
        marginBlock: ".2rem",
    },
    section: { marginBlock: "1rem" },

    row: { justifyContent: "flex-start", display: "flex", flex: 1 },
    column: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        minWidth: "220px",
    },
    text: {
        border: "1px solid black",
        flex: 1,
        padding: ".2rem",
        minHeight: "50px",
        minWidth: "220px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textAlignRight: {
        border: "1px solid black",
        flex: 1,
        padding: ".2rem",
        minHeight: "40px",
        minWidth: "220px",
        justifyContent: "flex-end",
        display: "flex",
        alignItems: "center",
        paddingInline: "1rem",
    },
};

export const EstateSyncAdminPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean | string>(false);
    const [loadingProperties, setLoadingProperties] = useState(false);
    const [properties, setProperties] = useState<Property[]>([]);
    // const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const { isAdmin } = useApp()

    const fetchSyncedProperties = useCallback(async () => {
        try {
            setLoadingProperties(true);
            const fetchedProperties = await getAllSyncedPropertiesAPI();
            setProperties(fetchedProperties || []);
        } catch (error) {
            console.error("could not fetch properties", error);
        } finally {
            setLoadingProperties(false);
        }
    }, []);

    const toggleEstateSync = async (id: string) => {
        setLoading(true);
        try {
            const res = await toggleEstateSyncAPI(id);
            await fetchSyncedProperties();
        } catch (error) {
            console.error("could not toggle estate sync", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteProperty = async (id: string) => {
        const res = await deleteAndUnsyncPropertyAPI(id);
        await fetchSyncedProperties();
    };

    useEffect(() => {
        fetchSyncedProperties();
    }, []);

    return (
        isAdmin ?
            <Box sx={{ flexDirection: "column" }}>
                <Box sx={styles.row}>
                    <Box sx={styles.section}>
                        <Box sx={styles.row}>
                            <Typography sx={styles.text}>
                                Selbst-Vermarkten ID
                            </Typography>
                            <Typography sx={styles.text}>User</Typography>
                            <Typography sx={styles.text}>Estate Sync ID</Typography>
                            <Typography sx={styles.text}>Aktiv/Inaktiv</Typography>
                            <Typography sx={{ ...styles.text, minWidth: "400px" }}>
                                Links
                            </Typography>
                            <Typography sx={styles.textAlignRight}>
                                Aktionen
                            </Typography>
                        </Box>
                        {properties.map((property) => (
                            <Box key={property.id} sx={styles.row}>
                                <Box sx={styles.row}>
                                    <Typography sx={styles.text}>
                                        {property.id}
                                    </Typography>
                                    <Typography sx={styles.text}>
                                        <a
                                            href={`${process.env.REACT_APP_ADMIN_URL}/accounts/user/${property.user}/change/`}
                                        >
                                            {property.user}
                                        </a>
                                    </Typography>
                                    <Typography sx={styles.text}>
                                        {property.estate_sync_property_id
                                            ? property.estate_sync_property_id
                                            : "-- keine estate sync id --"}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            ...styles.text,
                                            justifyContent: "center",
                                            color: property.is_active
                                                ? "green"
                                                : "red",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {property.is_active ? "Aktiv" : "--"}
                                    </Typography>

                                    <Box
                                        sx={{
                                            ...styles.text,
                                            flexDirection: "column",
                                            alignItems: "left",
                                            minWidth: "400px",
                                        }}
                                    >
                                        {property.django_admin_link && (
                                            <a
                                                href={property.django_admin_link}
                                                style={styles.link}
                                                target="_blank"
                                            >
                                                Selbst-Vermarkten-Admin
                                            </a>
                                        )}
                                        {property.estate_sync_link &&
                                            property.estate_sync_property_id && (
                                                <>
                                                    <a
                                                        href={
                                                            property.estate_sync_link
                                                        }
                                                        style={styles.link}
                                                        target="_blank"
                                                    >
                                                        Estate-Sync-Admin
                                                    </a>
                                                </>
                                            )}

                                        {property.public_urls && (
                                            <Typography
                                                sx={{
                                                    marginTop: "1rem",
                                                    fontWeight: 600,
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                Öffentliche Links:
                                            </Typography>
                                        )}
                                        {property.public_urls &&
                                            property.public_urls.map((url) => (
                                                <a
                                                    key={url}
                                                    style={styles.link}
                                                    href={url}
                                                    target="_blank"
                                                >
                                                    {url}
                                                </a>
                                            ))}
                                    </Box>
                                    {/*  ACTIONS */}
                                    {loading || loadingProperties ? (
                                        <Box sx={styles.textAlignRight}>
                                            <CircularProgress size={30} />
                                        </Box>
                                    ) : (
                                        <Box sx={styles.textAlignRight}>
                                            {!property.is_active && (
                                                <Tooltip title="Aktivieren und auf Estate Sync pushen">
                                                    <IconButton
                                                        onClick={() =>
                                                            toggleEstateSync(
                                                                String(property.id),
                                                            )
                                                        }
                                                    >
                                                        <CheckIcon
                                                            sx={{
                                                                color: "green",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {property.is_active && (
                                                <Tooltip title="Deaktivieren und von Estate Sync entfernen">
                                                    <IconButton
                                                        onClick={() =>
                                                            toggleEstateSync(
                                                                String(property.id),
                                                            )
                                                        }
                                                    >
                                                        <CloseIcon
                                                            sx={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <Tooltip title="Löschen und von Estate Sync entfernen">
                                                <IconButton
                                                    onClick={() =>
                                                        deleteProperty(
                                                            String(property.id),
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon
                                                        sx={{
                                                            color: "red",
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                {/* {process.env.NODE_ENV === "development" && (
                <Button
                    onClick={async () => {
                        await unsyncAllPropertiesAPI();
                        await fetchSyncedProperties();
                    }}
                >
                    Alle Properties unsyncen
                </Button>
            )} */}
            </Box> : <LoginPage isAdminPage={true} />
    );
};
