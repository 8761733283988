import { Box } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { LabeledTextAreaInput, LabeledTextInput } from "components";
import { getLocalizedLabels } from "services";
import { LABELS } from "../../constants";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const StepSevenForm = () => {
    const { id } = useParams();

    const labels = getLocalizedLabels(LABELS);
    const {
        property,
        validationErrors,
        handleTextChange,
        handleTextAreaChange,
    } = id ? usePropertyEdit() : usePropertyCreation();

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <Box
                sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <LabeledTextInput
                    label={labels.title + "*"}
                    name="title"
                    placeholder={labels.title}
                    handleChange={handleTextChange}
                    value={property.title}
                    mb="1.5rem"
                    fullWidth
                    hasError={validationErrors.includes("title")}
                />
                <LabeledTextAreaInput
                    label={labels.description + "*"}
                    name="description"
                    placeholder={labels.description}
                    handleTextAreaChange={handleTextAreaChange}
                    value={property.description}
                    hasError={validationErrors.includes("description")}
                    mb="1.5rem"
                    fullWidth
                />
                <LabeledTextAreaInput
                    label={labels.description_of_equipment}
                    name="description_of_equipment"
                    placeholder={labels.description_of_equipment}
                    handleTextAreaChange={handleTextAreaChange}
                    value={property.description_of_equipment}
                    mb="1.5rem"
                    fullWidth
                />
                <LabeledTextAreaInput
                    label={labels.description_of_location}
                    name="description_of_location"
                    placeholder={labels.description_of_location}
                    handleTextAreaChange={handleTextAreaChange}
                    value={property.description_of_location}
                    mb="1.5rem"
                    rows={5}
                    fullWidth
                />
            </Box>
        </Box>
    );
};
